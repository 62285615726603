<template>
  <v-container
    v-if="permission.read_perm === 1"
    style="padding: 0; margin: auto; border-radius: 5px; background:white;"
  >
    <v-form
      ref="entryForm"
      @submit.prevent="submit"
      :disabled="permission.create_perm === 0"
      :readonly="loading"
    >
      <v-card
        flat
        style="
          border-bottom: 1px solid black;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
        >
          <v-toolbar-title class="overline">
            <p
              :style="
                `font-size: ${
                  wWidth > 779 ? 20 : 14
                }px; margin: 0; font-weight: bold`
              "
            >
              Formulir Peminjaman Arsip Dokumen
            </p>
          </v-toolbar-title>
        </div>
        <div class="d-flex">
          <div v-if="wWidth > 779">
            <v-btn
              v-if="permission.create_perm === 1"
              :loading="loading"
              type="submit"
              rounded
              outlined
              small
              elevation="1"
              color="indigo"
              class="indigo--text"
              style="font-size:12px;margin-right:10px;"
            >
              simpan
            </v-btn>
          </div>

          <v-btn
            :loading="loading"
            :rounded="wWidth > 779"
            :outlined="wWidth > 779"
            small
            :elevation="wWidth > 779 ? 1 : 0"
            color="red"
            class="red--text"
            style="font-size:12px;margin-right:10px;"
            @click="close"
            :icon="wWidth < 780"
          >
            <span v-if="wWidth > 779"> Tutup</span>
            <v-icon v-else color="red">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>

      <v-row style="padding: 15px">
        <v-col
          v-if="isAdmin"
          cols="12"
          md="5"
          style="padding: 0; margin-bottom:20px;"
        >
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Cari nama karyawan terdaftar
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <!-- <v-text-field
                :value="getUserProfile.employee.name"
                readonly
                dense
                outlined
                style="margin: 0"
              /> -->

              <v-autocomplete
                v-model="form.employee"
                dense
                outlined
                item-text="name"
                item-id="id"
                hide-details
                hide-no-data
                return-object
                :loading="loading"
                :items="itemss"
                :search-input.sync="search"
                clearable
              ></v-autocomplete>
              <!-- <v-autocomplete
                v-model="form.employee"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                clearable
                label="Cari nama peminjam disini"
              ></v-autocomplete> -->
            </v-col>
          </div>
        </v-col>
        <v-col v-if="isAdmin" cols="12" md="7" style="padding: 0"></v-col>

        <v-col cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nama Peminjam
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.pic"
                dense
                outlined
                style="margin: 0"
                readonly
                :rules="picNameRules"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="2" style="padding: 0"></v-col>
        <v-col cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Email
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                dense
                outlined
                style="margin: 0"
                readonly
              />
            </v-col>
          </div>
        </v-col>

        <v-col cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Plant
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <!-- <v-text-field
                :value="getUserProfile.employee.company.name"
                dense
                outlined
                readonly
                style="margin: 0"
              /> -->

              <v-autocomplete
                v-model="form.plant"
                :items="getDropdownPlant"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                readonly
                :clearable="isAdmin"
                style="margin: 0"
                :rules="plantRules"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="2" style="padding: 0"></v-col>
        <v-col cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Department
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <!-- <v-text-field
                :value="getUserProfile.employee.department.name"
                dense
                outlined
                readonly
                style="margin: 0"
              /> -->
              <v-autocomplete
                v-model="form.department"
                :items="dropdown.department"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                readonly
                :clearable="isAdmin"
                style="margin: 0"
                :rules="departmentRules"
              />
            </v-col>
          </div>
        </v-col>

        <!-- <v-col v-if="isAdmin" cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Peminjam
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.employee_name"
                dense
                outlined
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="7" style="padding: 0"></v-col> -->

        <v-col cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tipe Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="param.doc_type"
                :items="[
                  {
                    name: '-',
                    id: ''
                  },
                  {
                    name: 'Hard Copy',
                    id: 'admin'
                  },
                  {
                    name: 'Soft Copy',
                    id: 'public_user'
                  }
                ]"
                item-text="name"
                item-value="id"
                return-id
                dense
                outlined
                style="margin: 0"
                :disabled="getDocId.id !== null"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="2" style="padding: 0"></v-col>
        <v-col cols="12" md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nama Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                @change="cekDocStatus"
                v-if="getDocId.id === null"
                v-model="form.doc"
                :items="getDropdownArchive"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                style="margin: 0;"
                :disabled="param.doc_type === ''"
                :rules="docRules"
              />
              <v-text-field
                v-else
                dense
                outlined
                readonly
                :value="getDocId.name"
                style="margin: 0; "
              />
              <p
                :style="
                  `margin:0;font-size:12px;position:relative;top:-25px;color:${
                    docStatus.msg !== 'Document available' ? 'red' : 'green'
                  };`
                "
              >
                {{ docStatus.msg }}
              </p>
            </v-col>
          </div>
        </v-col>

        <v-col
          v-if="param.doc_type === 'admin'"
          cols="12"
          md="5"
          style="padding: 0"
        >
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Jumlah
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                type="number"
                v-model="form.qty"
                dense
                outlined
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col
          v-if="param.doc_type === 'admin'"
          md="2"
          style="padding: 0"
        ></v-col>
        <v-col
          v-if="param.doc_type === 'admin'"
          cols="12"
          md="5"
          style="padding: 0"
        >
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Satuan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="form.qty_uom"
                :items="[
                  { id: 1, name: 'Pcs' },
                  { id: 2, name: 'Lbr' },
                  { id: 3, name: 'Book' },
                  { id: 4, name: 'Set' }
                ]"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col
          v-if="param.doc_type === 'admin'"
          cols="12"
          md="5"
          style="padding: 0"
        >
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl Peminjaman
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0"
              ><v-text-field
                v-model="form.loan_start_date"
                outlined
                dense
                type="date"
                name="date"
                step="1"
                :rules="loanStartDateRules"
              />
            </v-col>
          </div>
        </v-col>
        <v-col
          v-if="param.doc_type === 'admin'"
          cols="12"
          md="2"
          style="padding: 0"
        ></v-col>
        <v-col
          v-if="param.doc_type === 'admin'"
          cols="12"
          md="5"
          style="padding: 0"
        >
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl Pengembalian
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.loan_end_date"
                outlined
                dense
                type="date"
                name="date"
                step="1"
                :rules="loanEndDateRules"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tujuan Penggunaan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-textarea
                v-model="form.loan_purpose"
                dense
                auto-grow
                outlined
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <div v-if="wWidth < 780" style="padding: 0 10px;">
        <v-btn
          v-if="permission.create_perm === 1"
          type="submit"
          rounded
          elevation="1"
          color="indigo"
          class="white--text"
          style="font-size:14px;width:100%;position:relative; bottom:10px;"
        >
          simpan
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'loaningform',
  data() {
    return {
      wWidth: window.innerWidth,
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      download: buildType.apiURL('download'),
      hr: buildType.apiURL('hr'),
      build: process.env.VUE_APP_BUILD_TYPE,
      isAdmin: null,
      // menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 31 : 29,
      menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 29 : 29,
      application: {
        id: 14,
        name: 'eArchive'
      },
      permission: {},
      dialog: false,
      param: {
        doc_type: '',
        find_employee: false
      },
      form: {},
      loading: false,
      select: null,
      search: null,
      itemss: [],
      new: [],

      docRules: [],
      // employeeIdRules: [],
      // employeeNikRules: [],
      picNameRules: [],
      plantRules: [],
      departmentRules: [],
      emailRules: [],
      qtyRules: [],
      unitRules: [],
      loanStartDateRules: [],
      loanEndDateRules: [],
      docStatus: {
        code: '',
        msg: ''
      },
      dropdown: {
        department: []
      }
    }
  },
  computed: {
    ...mapGetters([
      // 'getDropdownDepartment',
      // 'getDropdownUnit',
      'getDropdownPlant',
      'getDropdownArchive',
      'getDropdownEmployee',
      'getDocId',
      'getUserProfile'
    ])
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    const a = this.build === 'development' ? '20' : '20'
    const b = this.build === 'development' ? '19' : '21'
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            if (
              arr[i].id === '1' ||
              arr[i].id === a ||
              arr[i].id === b ||
              arr[i].id === '32'
            ) {
              if (
                arr[i].id === '1' ||
                arr[i].id === b ||
                arr[i].id === '20' ||
                arr[i].id === '32'
              ) {
                this.isAdmin = true
                // this.initDropdown('admin')
                this.initDropdown(this.param.doc_type)
              } else {
                this.isAdmin = false
                // this.initDropdown('public_user')
                this.initDropdown(this.param.doc_type)
              }
              this.initForm()
            } else {
              this.isAdmin = false
              // this.initDropdown('public_user')
              this.initDropdown(this.param.doc_type)
              this.initFormUser()
            }
          })
          .catch(error => {
            if (error) {
              this.isAdmin = null
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = null
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    'param.doc_type'() {
      this.initDropdown(this.param.doc_type)
    },
    'form.employee'() {
      if (this.form.employee !== null) {
        let department = null
        if (this.form.employee.department.section !== null) {
          if (
            this.form.employee.department.section.id === null ||
            this.form.employee.department.section.id === ''
          ) {
            department = {
              id: Number(this.form.employee.department.id),
              name: this.form.employee.department.name
            }
          } else {
            department = {
              id: Number(this.form.employee.department.section.id),
              name: this.form.employee.department.section.name
            }
          }
        } else {
          if (this.form.employee.department.unit !== null) {
            if (
              this.form.employee.department.unit.id === null ||
              this.form.employee.department.unit.id === ''
            ) {
              department = {
                id: Number(this.form.employee.department.id),
                name: this.form.employee.department.name
              }
            } else {
              department = {
                id: Number(this.form.employee.department.unit.id),
                name: this.form.employee.department.unit.name
              }
            }
          } else {
            department = {
              id: Number(this.form.employee.department.id),
              name: this.form.employee.department.name
            }
          }
        }

        this.param.find_employee = true
        this.form.email = this.form.employee.email
        this.form.pic = this.form.employee.name
        this.form.department = department
        this.form.plant = {
          id: this.form.employee.company_id,
          name: this.form.employee.company_name
        }
      } else {
        if (this.isAdmin) {
          this.param.find_employee = false
          this.form.email = ''
          this.form.pic = ''
          this.form.department = null
          this.form.plant = null
        }
      }
    },
    'form.plant'() {
      if (this.form.plant !== null) {
        this.dropdownDepartment()
      }
    }
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    ...mapActions([
      // 'dropdownDepartment',
      // 'dropdownUnit',
      'dropdownPlant',
      'dropdownArchive',
      'dropdownEmployee',
      'menu_access'
    ]),
    ...mapMutations([
      'setPermission',
      'setLoaningDocumentName',
      'setLoaningDocId'
    ]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.docRules = []
      // this.employeeIdRules = [];
      // this.employeeNikRules = [];
      this.picNameRules = []
      this.plantRules = []
      this.departmentRules = []
      this.emailRules = []
      this.qtyRules = []
      this.unitRules = []
      this.loanStartDateRules = []
      this.loanEndDateRules = []
      this.form = {}
      this.docStatus.code = ''
      this.docStatus.msg = ''
      this.loading = false
    },
    clickOutside() {
      this.close()
    },
    close() {
      this.setLoaningDocumentName(['', null, null])
      this.$router.push('/archive-management/loaning-list/')
    },
    // loadForm() {
    //   console.log(this.param)
    // },
    initForm() {
      this.search = this.getUserProfile.employee.name
      this.param.doc_type = this.getDocId.type
      this.form = {
        doc: '',
        employee: null,
        employee_id: 0,
        employee_name: '',
        employee_nik: '',
        pic: '',
        plant: null,
        department: null,
        email: '',
        qty: this.getDocId.id === null ? '' : this.getDocId.qty,
        qty_uom: this.getDocId.id === null ? '' : this.getDocId.uom,
        loan_start_date: '',
        loan_end_date: '',
        loan_purpose: ''
      }
    },
    initFormUser() {
      this.param.doc_type = this.getDocId.type
      // GET IDENTIFY DEPARTMENT
      let department = null
      if (this.getUserProfile.employee.department.section !== null) {
        if (
          this.getUserProfile.employee.department.section.id === null ||
          this.getUserProfile.employee.department.section.id === ''
        ) {
          department = {
            id: Number(this.getUserProfile.employee.department.id),
            name: this.getUserProfile.employee.department.name
          }
        } else {
          department = {
            id: Number(this.getUserProfile.employee.department.section.id),
            name: this.getUserProfile.employee.department.section.name
          }
        }
      } else {
        if (this.getUserProfile.employee.department.unit !== null) {
          if (
            this.getUserProfile.employee.department.unit.id === null ||
            this.getUserProfile.employee.department.unit.id === ''
          ) {
            department = {
              id: Number(this.getUserProfile.employee.department.id),
              name: this.getUserProfile.employee.department.name
            }
          } else {
            department = {
              id: Number(this.getUserProfile.employee.department.unit.id),
              name: this.getUserProfile.employee.department.unit.name
            }
          }
        } else {
          department = {
            id: Number(this.getUserProfile.employee.department.id),
            name: this.getUserProfile.employee.department.name
          }
        }
      }

      this.form = {
        doc: '',
        pic: this.getUserProfile.employee.name,
        employee: null,
        employee_id: this.getUserProfile.employee_id,
        employee_name: this.getUserProfile.employee.name,
        employee_nik: this.getUserProfile.employee.nik,
        plant: {
          id: Number(this.getUserProfile.employee.company.plant_id),
          name: this.getUserProfile.employee.company.name
        },
        department: department,
        email: this.getUserProfile.employee.email,
        qty: this.getDocId.id === null ? '' : this.getDocId.qty,
        qty_uom: this.getDocId.id === null ? '' : this.getDocId.uom,
        loan_start_date: '',
        loan_end_date: '',
        loan_purpose: ''
      }
      console.log(this.getUserProfile.employee)
    },
    initDropdown(val) {
      // this.dropdownUnit()
      this.dropdownPlant()
      this.dropdownArchive(val)
      // this.dropdownDepartment()
    },
    submit() {
      this.docRules = [v => !!v || 'Document is required']
      // this.employeeIdRules = [(v) => !!v || "Employee Id is required"];
      // this.employeeNikRules = [(v) => !!v || "Employee NIK is required"];
      this.picNameRules = [v => !!v || 'PIC Name is required']
      this.plantRules = [v => !!v || 'PIC Plant is required']
      this.departmentRules = [v => !!v || 'PIC Department is required']
      this.emailRules = [v => !!v || 'PIC Email is required']
      this.qtyRules = [v => !!v || 'Quantity is required']
      this.unitRules = [v => !!v || 'Unit is required']
      this.loanStartDateRules = [v => !!v || 'Loan Start Date is required']
      this.loanEndDateRules = [v => !!v || 'Loan End Date is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          if (self.param.doc_type === 'public_user') {
            self.createNewFormUser()
          } else {
            self.createNewForm()
            console.log(self.param.doc_type)
          }
          // }
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: 'add',
        doc_id:
          this.getDocId.id === null
            ? Number(this.form.doc.id)
            : this.getDocId.id,
        plant_id: this.form.plant.id,
        plant_name: this.form.plant.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        qty: this.form.qty !== null ? Number(this.form.qty) : 0,
        qty_uom_id: this.form.qty_uom !== null ? this.form.qty_uom.id : null,
        loan_start_date: this.form.loan_start_date,
        loan_end_date: this.form.loan_end_date,
        loan_purpose: this.form.loan_purpose
      }
      if (this.param.find_employee) {
        Object.assign(newForm, {
          employee_id: this.form.employee.id,
          employee_nik: this.form.employee.nik,
          employee_name: this.form.employee.name,
          pic_name: this.form.pic,
          email: this.form.email
        })
      } else {
        Object.assign(newForm, {
          employee_id: 0,
          employee_nik: '',
          employee_name: this.form.pic,
          pic_name: this.form.pic,
          email: this.form.email
        })
      }
      console.log(newForm)
      this.save(newForm)
    },
    save(form) {
      this.loading = true
      axios
        .post(`${this.eSanqua}archive_document/loan/save`, form)
        .then(res => {
          this.loading = false
          const createdID = res.data.created_id
          if (res.data.status_code !== '-99') {
            this.setLoaningDocId([
              createdID,
              this.getDocId.id === null
                ? this.form.doc.name
                : this.getDocId.name
            ])
            this.form.pic = null
            this.clear()
            this.showMsgDialog('success', res.data.status_msg, false)
            setTimeout(() => {
              this.$router.push('/archive-management/loaning-list/detail')
            }, 300)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    createNewFormUser() {
      const newForm = {
        act: 'add',
        doc_id:
          this.getDocId.id === null
            ? Number(this.form.doc.id)
            : this.getDocId.id,
        plant_id: this.form.plant.id,
        plant_name: this.form.plant.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        loan_purpose: this.form.loan_purpose,
        qty: this.form.qty !== null ? Number(this.form.qty) : 0,
        qty_uom_id: this.form.qty_uom !== null ? this.form.qty_uom.id : null
      }
      if (this.param.find_employee) {
        Object.assign(newForm, {
          employee_id: this.form.employee.id,
          employee_nik: this.form.employee.nik,
          employee_name: this.form.employee.name,
          pic_name: this.form.pic,
          email: this.form.email
        })
      } else {
        Object.assign(newForm, {
          employee_id: 0,
          employee_nik: '',
          employee_name: this.form.pic,
          pic_name: this.form.pic,
          email: this.form.email
        })
      }
      console.log(newForm)
      this.saveUser(newForm)
    },
    saveUser(form) {
      this.loading = true
      axios
        .post(`${this.eSanqua}archive_document/self_service_loan/save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            const createdID = res.data.created_id
            this.setLoaningDocId([
              createdID,
              this.getDocId.id === null
                ? this.form.doc.name
                : this.getDocId.name
            ])
            this.clear()
            this.showMsgDialog('success', res.data.status_msg, false)
            setTimeout(() => {
              this.$router.push('/archive-management/loaning-list/detail')
            }, 300)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
            this.loading = false
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    querySelections(v) {
      // axios
      //   .get(`${this.hr}employee/list?keyword=${v}&company_id=`)
      //   .then(res => {
      //     this.itemss = res.data.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })

      axios
        .get(`${this.hrsApi}employee/list?keyword=${v}&offset=0&limit=10`)

        .then(res => {
          this.itemss = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    cekDocStatus(event) {
      this.form.qty = event.doc_qty
      this.form.qty_uom = event.doc_unit

      this.docStatus.code = ''
      this.docStatus.msg = ''
      axios
        .get(`${this.eSanqua}archive_document/status/${event.id}`)
        .then(res => {
          this.docStatus.code = res.data.status_code
          this.docStatus.msg = res.data.status_msg
        })
        .catch(err => {
          console.log(err)
        })
    },
    async dropdownDepartment() {
      let company_id = ''
      if (this.isAdmin) {
        company_id = this.form.plant.id
      } else {
        company_id = this.getUserProfile.employee.company.plant_id
      }
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${company_id}}]`
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    }
  }
}
</script>
